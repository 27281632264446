import moment from "moment";
import pxd from "parse-xsd-duration";
import { toast } from "react-toastify";
import {
  PASSWORD_STRENGTH,
  ENV_MAPPER,
  APP_PERMISSION_MAPPER,
  IATA_LIST,
  SHARED,
  OWNED,
  SUBAGENCY,
  NOLONGER,
  BAGGAGE,
  PENALTY_CODE,
  LI_OWNER_CODE,
  IS_LOCAL_INVENTORY_SUBSCRIPTION,
} from "utils/constants";
import jwt_decode from "jwt-decode";
const md5 = require("md5");

export interface ProxyObjectType {}

const jwt = require("jsonwebtoken");

const emptyProxyObject: ProxyObjectType = new Proxy(
  {},
  {}
  // {
  //     get: () => {
  //         return emptyProxyObject;
  //     },
  // }
);

const isEmpty = (val: any) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

export const getValue = (val: any) => {
  return isEmpty(val) ? "-" : val;
};

export interface PasswordStrength {
  length: Boolean;
  numeric: Boolean;
  lowerCase: Boolean;
  upperCase: Boolean;
  specialCharacter: Boolean;
}

export const passwordValidator = (value: any) => {
  const result = {
    length: false,
    lowerCase: false,
    upperCase: false,
    specialCharacter: false,
    numeric: false,
  };
  if (!isEmpty(value)) {
    if (PASSWORD_STRENGTH.MIN_LENGTH <= value.length) {
      result.length = true;
    }
    if (value.match(/[_A-Z]/)) {
      result.upperCase = true;
    }
    if (value.match(/[_a-z]/)) {
      result.lowerCase = true;
    }
    if (value.match(/[_0-9]/)) {
      result.numeric = true;
    }
    if (value.match(/[_\W]/)) {
      result.specialCharacter = true;
    }
  }
  return result;
};

export const notify = (type: string, message: string) => {
  type === "success"
    ? toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      })
    : type === "warning"
    ? toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      })
    : toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
      });
};

const setLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string) => {
  const value: any = window.localStorage.getItem(key);
  return !isEmpty(value) ? JSON.parse(value) : null;
};

const clearLocalStorage = (key: Array<any>) => {
  key.forEach((item) => window.localStorage.removeItem(item));
};

const setSessionStorage = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionStorage = (key: string) => {
  const value: any = window.sessionStorage.getItem(key);
  return !isEmpty(value) ? JSON.parse(value) : null;
};

const clearSessionStorage = (key: Array<any>) => {
  key.forEach((item) => window.sessionStorage.removeItem(item));
};

const counterValueHandler = (value: number, type: string) => {
  let temp = value;
  if (type === "-" && value !== 0) {
    temp = temp - 1;
  } else if (type === "+") {
    temp = temp + 1;
  }
  return temp;
};

const dateChangeHandler = (value: any, type: string) => {
  let temp = "";
  if (type === "-") {
    temp = moment(value).subtract(1, "days").format();
  } else if (type === "+") {
    temp = moment(value).add(1, "days").format();
  }
  return temp;
};

const buildDateToSQLFmt = (ipDate: any) => {
  return (
    new Date(ipDate).getFullYear() +
    "-" +
    ("0" + (new Date(ipDate).getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date(ipDate).getDate()).slice(-2)
  );
};
const buildDateFromSQLFmt = (ipDate: any) => {
  let year = ipDate?.split("-")[0];
  let month = ipDate?.split("-")[1] - 1;
  let day = ipDate?.split("-")[2];
  return new Date(year, month, day);
};

const compareObjects = (obj1: any, obj2: any) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
};

const convertDurationForUI = (duration: any) => {
  const converted = pxd(duration, true);
  let convertedFormat = "";

  if (converted?.days) {
    convertedFormat += `${converted.days} <span>${
      converted.days > 1 ? "days" : "day"
    }</span> `;
  }
  if (converted?.hours) {
    convertedFormat += `${converted.hours} <span>hr</span> `;
  }
  if (converted?.minutes) {
    convertedFormat += `${converted.minutes} <span>mins</span>`;
  }

  return convertedFormat.trim();
};

const deepClone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

const generateRedirectAppUrl = (host: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (
    baseUrl?.includes("localhost") ||
    baseUrl?.includes("127.0.0.1") ||
    baseUrl?.includes(".dev.")
  ) {
    return `https://gw.${host}.dev.llc.nuflights.com/`;
  }
  if (baseUrl?.includes(".qa.")) {
    return `https://${host}.qa.llc.nuflights.com/`;
  }
  if (baseUrl?.includes(".staging.")) {
    return `https://${host}.staging.llc.nuflights.com/`;
  }
  if (baseUrl?.includes(".intg.")) {
    return `https://${host}.intg.llc.nuflights.com/`;
  }
  return `https://${host}.v3.nuflights.com/`;
};

const getEnv = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (baseUrl?.includes("localhost") || baseUrl?.includes("127.0.0.1")) {
    return ENV_MAPPER.local;
  }
  if (baseUrl?.includes(".dev.")) {
    return ENV_MAPPER.development;
  }
  if (baseUrl?.includes(".qa.")) {
    return ENV_MAPPER.qa;
  }
  if (baseUrl?.includes(".staging.")) {
    return ENV_MAPPER.staging;
  }
  if (baseUrl?.includes(".intg.")) {
    return ENV_MAPPER.integration;
  }
  return ENV_MAPPER.production;
};

const verifyAuthToken = (authToken: string, appPermission: string) => {
  let decodedToken: any, scope;
  try {
    decodedToken = jwt_decode(authToken);
    scope = decodedToken?.scope?.split(" ") || [];
  } catch (err) {
    console.error(err);
  }
  return Boolean(scope?.includes(APP_PERMISSION_MAPPER[appPermission]));
};

const convertCurrencyLocale = (currency: any, authToken: string) => {
  let decodedToken: any = jwt_decode(authToken);
  let locale = decodedToken["user_locale"] || "en-GB";
  const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 2 });
  return formatter.format(currency);
};

const nodeEnv = process.env.NODE_ENV;
// const generateRedirectUrl = (host : string) =>
//     nodeEnv === "production"
//         ? `https://${host}.nuflights.com/`
//         : `https://${host}.${ENV_MAPPER[nodeEnv] || "dev"}.nuflights.com/`;

const transactionIdMapper = (nfOrderId: any) => {
  const localTransaction = getSessionStorage("transaction") || null;
  if (!isEmpty(nfOrderId) && localTransaction?.nfOrderId === nfOrderId) {
    return localTransaction.id;
  } else if (
    nfOrderId === "airshopping" &&
    !isEmpty(localTransaction?.id) &&
    isEmpty(localTransaction.nfOrderId)
  ) {
    return localTransaction?.id;
  } else {
    clearSessionStorage(["transaction"]);
    return null;
  }
};

const convertPassengerDetails = (data: any) => {
  const passCount: any = {
    ADT: 0,
    CHD: 0,
    INF: 0,
  };
  data?.forEach((pass: any) => {
    passCount[pass.ptc] = passCount[pass.ptc] + 1;
  });
  let showText: any = "";
  if (passCount.ADT !== 0) {
    showText = showText + passCount.ADT + " adult(s)";
  }
  if (passCount.CHD !== 0) {
    showText = showText
      ? `${showText}, ${passCount.CHD} child(s)`
      : `${passCount.CHD} child(s)`;
  }
  if (passCount.INF !== 0) {
    showText = showText
      ? `${showText}, ${passCount.INF} infant(s)`
      : `${passCount.INF} infant(s)`;
  }
  return showText;
};

// const convertPassengerToMd5 = (
//   firstname: string,
//   middlename: string,
//   lastname: string,
//   birth: string,
//   passIndividualHashBy?: string
// ) => {
//   const firstName = firstname ? firstname.trim() : "";
//   const middleName = middlename ? middlename.trim() : "";
//   const lastName = lastname ? lastname.trim() : "";
//   const dob = birth;
//   let birthdate = "";
//   if (!isEmpty(dob)) {
//     const date = new Date(dob);
//     const day =
//       date.getDate().toString().length === 1
//         ? `0${date.getDate().toString()}`
//         : date.getDate().toString();
//     const getMonth: any = date.getMonth();
//     const monthFinal = parseInt(getMonth) + 1;
//     const month =
//       monthFinal.toString().length === 1
//         ? `0${monthFinal.toString()}`
//         : monthFinal.toString();
//     const year = date.getFullYear().toString();
//     birthdate = `${year}${month}${day}`;
//   }
//   let passengerDetail = `${firstName ? firstName.toUpperCase() : ""}${
//     middleName ? middleName.toUpperCase() : ""
//   }${lastName ? lastName.toUpperCase() : ""}${birthdate ? birthdate : ""}`;

//   const passIndividualHashArr = !isEmpty(passIndividualHashBy)
//     ? passIndividualHashBy?.split(":")
//     : [];
//   if (passIndividualHashArr) {
//     if (passIndividualHashArr.length >= 4) {
//       passengerDetail = `${firstName ? firstName.toUpperCase() : ""}${
//         middleName ? middleName.toUpperCase() : ""
//       }${lastName ? lastName.toUpperCase() : ""}${birthdate ? birthdate : ""}`;
//     } else if (passIndividualHashArr.length < 4) {
//       passengerDetail = `${firstName ? firstName.toUpperCase() : ""}${
//         lastName ? lastName.toUpperCase() : ""
//       }${birthdate ? birthdate : ""}`;
//     }
//   }
// }

const convertPassengerToMd5 = (
  firstname: string,
  middlename: string,
  lastname: string,
  birth: string,
  passIndividualHashBy?: string
) => {
  const firstName = firstname ? firstname.trim() : "";
  const middleName = middlename ? middlename.trim() : "";
  const lastName = lastname ? lastname.trim() : "";
  const dob = birth;
  let birthdate = "";
  if (!isEmpty(dob)) {
    const date = new Date(dob);
    const day =
      date.getDate().toString().length === 1
        ? `0${date.getDate().toString()}`
        : date.getDate().toString();
    const getMonth: any = date.getMonth();
    const monthFinal = parseInt(getMonth) + 1;
    const month =
      monthFinal.toString().length === 1
        ? `0${monthFinal.toString()}`
        : monthFinal.toString();
    const year = date.getFullYear().toString();
    birthdate = `${year}${month}${day}`;
  }

  let passengerDetail = "";

  const passIndividualHashArr = !isEmpty(passIndividualHashBy)
    ? passIndividualHashBy?.split(":")
    : [];

  if (passIndividualHashArr) {
    if (passIndividualHashArr?.includes("FirstName")) {
      passengerDetail = passengerDetail + `${firstName ? firstName : ""}`;
    }

    if (passIndividualHashArr?.includes("MiddleName")) {
      passengerDetail = passengerDetail + `${middleName ? middleName : ""}`;
    }

    if (passIndividualHashArr?.includes("LastName")) {
      passengerDetail = passengerDetail + `${lastName ? lastName : ""}`;
    }

    if (passIndividualHashArr?.includes("DOB")) {
      passengerDetail = passengerDetail + `${birthdate ? birthdate : ""}`;
    }
  }
  return md5(passengerDetail);
};

const getPassengerListByHash = (
  orderPassengerList: any[],
  fulfillmentOrderPassengers: any,
  passengerIndividualHasByAirline?: any
) => {
  const newPassengerList: any[] = [];
  if (!isEmpty(fulfillmentOrderPassengers)) {
    for (const passenger of fulfillmentOrderPassengers) {
      const findPassengerInOrderData = orderPassengerList.find((list: any) => {
        const { individual } = list;
        const firstName = individual?.givenName[0];
        const middleName = individual?.middleName[0];
        const lastName = individual?.surname;
        const birthdate = individual?.birthdate;
        const passengerDetailInMd5 = convertPassengerToMd5(
          firstName,
          middleName,
          lastName,
          birthdate,
          passengerIndividualHasByAirline
        );
        if (passenger.node.passengerHash === passengerDetailInMd5) {
          return passenger;
        } else {
          return null;
        }
      });
      if (!isEmpty(findPassengerInOrderData)) {
        newPassengerList.push(findPassengerInOrderData);
      }
    }
  }
  return newPassengerList;
};

const getMissedPassengerListByHash = (
  orderPassengerList: any[],
  fulfillmentOrderPassengers: any,
  passengerIndividualHasByAirline?: any
) => {
  let newPassengerList: any[] = [];
  const missedPassengerHashList: any[] = [];
  const missedPassengerList: any = [];
  if (!isEmpty(fulfillmentOrderPassengers) && !isEmpty(orderPassengerList)) {
    for (const [index, passenger] of fulfillmentOrderPassengers.entries()) {
      const findPassengerInOrderData = orderPassengerList.find((list: any) => {
        const { individual } = list;
        const firstName = individual?.givenName[0];
        const middleName = individual?.middleName[0];
        const lastName = individual?.surname;
        const birthdate = individual?.birthdate;
        const passengerDetailInMd5 = convertPassengerToMd5(
          firstName,
          middleName,
          lastName,
          birthdate,
          passengerIndividualHasByAirline
        );
        if (passenger.node.passengerHash === passengerDetailInMd5) {
          return passenger;
        } else {
          return null;
        }
      });
      if (!isEmpty(findPassengerInOrderData)) {
        newPassengerList.push(findPassengerInOrderData);
      } else {
        missedPassengerHashList.push({
          recordIndex: index,
          record: passenger,
        });
      }
    }

    if (
      !isEmpty(newPassengerList) &&
      newPassengerList.length !== orderPassengerList.length
    ) {
      for (const orderPax of orderPassengerList) {
        const findOrderPaxInNewPassengerList = newPassengerList.find(
          (list: any) => list?.paxId === orderPax?.paxId
        );
        if (isEmpty(findOrderPaxInNewPassengerList)) {
          missedPassengerList.push(orderPax);
        }
      }
    }
  }

  return {
    missedPassengerHashList,
    missedPassengerList,
  };
};

const getOrderBookedType = (order: any) => {
  const lsIataList = getSessionStorage(IATA_LIST) || [];
  if (!isEmpty(lsIataList) && !isEmpty(order)) {
    const findIataRecord = lsIataList.find(
      (list: any) => list?.node?.number === order?.iataProfile?.number
    );
    if (!isEmpty(findIataRecord)) {
      if (findIataRecord?.node?.isSharedSubscription === true) {
        if (
          findIataRecord?.node?.organization?.ownAgenyId ===
          order?.relativeSeller?.agency?.id
        ) {
          return SHARED;
        } else {
          return SUBAGENCY;
        }
      } else {
        if (
          findIataRecord?.node?.organization?.id ===
          order?.relativeSeller?.agency?.id
        ) {
          return OWNED;
        } else {
          return SUBAGENCY;
        }
      }
    } else {
      return NOLONGER;
    }
  }
};

const orderBookedBy = (order: any, orgId: any) => {
  if (!isEmpty(order?.sharedSubscription?.id)) {
    if (orgId === order?.relativeSeller?.agency?.id) {
      return SHARED;
    } else {
      return SUBAGENCY;
    }
  } else {
    if (orgId === order?.relativeSeller?.agency?.id) {
      return OWNED;
    } else {
      return SUBAGENCY;
    }
  }
};

const ageCalculator = (depDate: any, selectedDate: any) => {
  // if (depDate.search("T") !== -1)
  //   depDate = depDate.split("T")[0] + "T24:00:00";

  // const type = moment(depDate).diff(
  //   moment(new Date(passengerData.dob)),
  //   "milliseconds"
  // );
  // const duration = moment.duration(type);

  // const years = duration.get("y");
  // const months = duration.get("M");
  // const days = duration.get("d");

  const dateOld = new Date(selectedDate).getDate();
  const monthOld = new Date(selectedDate).getMonth();
  const yearOld = new Date(selectedDate).getFullYear();

  const dateNew = new Date(depDate).getDate();
  const monthNew = new Date(depDate).getMonth();
  const yearNew = new Date(depDate).getFullYear();

  var a = moment([yearNew, monthNew, dateNew]);
  var b = moment([yearOld, monthOld, dateOld]);

  var years = a.diff(b, "year");
  b.add(years, "years");

  var months = a.diff(b, "months");
  b.add(months, "months");

  var days = a.diff(b, "days");

  return {
    years,
    months,
    days,
  };
};

const baseFareWithServiceFee = (
  key: any,
  offerItem: any,
  isReshopFlow: boolean = false
) => {
  const finalSupplierBaseFare =
    key === true
      ? Number(
          offerItem.reduce(
            (total: number, offer: any) =>
              total +
              Number(
                offer.farePriceType[0].price.baseAmount.cdata *
                  (!isReshopFlow ? offer.paxRefId.length : 1)
              ) +
              Number(offer.farePriceType[0].price?.fee[0]?.amount?.cdata),
            0.0
          )
        ).toFixed(2)
      : Number(
          offerItem.reduce(
            (total: number, offer: any) =>
              total +
              Number(
                offer.farePriceType[0].price.baseAmount.cdata *
                  (!isReshopFlow ? offer.paxRefId.length : 1)
              ),
            0.0
          )
        ).toFixed(2);
  return finalSupplierBaseFare;
};

const carrierFlightCodeAndNumber = (
  marketingCarrierCode: string,
  operatingCarrierCode: string,
  flightNumber: string
) => {
  if (marketingCarrierCode && operatingCarrierCode) {
    if (marketingCarrierCode === operatingCarrierCode) {
      return `${marketingCarrierCode} ${flightNumber}`;
    } else {
      return `${marketingCarrierCode}(${operatingCarrierCode} ${flightNumber})`;
    }
  } else {
    return `${marketingCarrierCode} ${flightNumber}`;
  }
};
// const getCalculatedFareAmounts = (orderItems: any) => {
//   let baseAmount: number = 0;
//   let taxAmount: number = 0;
//   let totalAmount: number = 0;
//   let discountAmount: number = 0;
//   let serviceFeeAmount: number = 0;
//   let paxLength: number = 0;

//   orderItems?.map((orderItems: any) => {
//     if (!isEmpty(orderItems["fareDetail"])) {
//       for (const fareItem of orderItems["fareDetail"]) {
//         if (fareItem?.farePriceType[0]?.farePriceTypeCode === "Sell") {
//           if (!isEmpty(fareItem?.paxRefId)) {
//             if (
//               fareItem?.paxRefId.length &&
//               fareItem?.paxRefId.length === 1 &&
//               fareItem?.paxRefId[0].search(" ") !== -1
//             ) {
//               paxLength = fareItem?.paxRefId[0]
//                 .replace("[", "")
//                 .replace("]", "")
//                 .replaceAll("'", "")
//                 .split(" ").length;
//             } else {
//               paxLength = fareItem?.paxRefId.length;
//             }
//           }
//           baseAmount =
//             baseAmount +
//             Number(
//               fareItem?.farePriceType[0]?.price?.baseAmount?.cdata * paxLength
//             );
//           taxAmount =
//             taxAmount +
//             Number(
//               fareItem?.farePriceType[0]?.price?.taxSummary[0]?.totalTaxAmount
//                 ?.cdata * paxLength
//             );
//           totalAmount = baseAmount + taxAmount;
//           discountAmount = !isEmpty(fareItem?.price?.discount)
//             ? discountAmount +
//               fareItem?.price?.discount?.discountAmount?.cdata * paxLength
//             : !isEmpty(fareItem?.farePriceType[0]?.price?.discount)
//             ? discountAmount +
//               fareItem?.farePriceType[0]?.price?.discount?.discountAmount
//                 ?.cdata *
//                 paxLength
//             : 0;
//           serviceFeeAmount = !isEmpty(fareItem?.price?.fee)
//             ? serviceFeeAmount +
//               fareItem?.price?.fee[0]?.amount?.cdata * paxLength
//             : !isEmpty(fareItem?.farePriceType[0]?.price?.fee)
//             ? serviceFeeAmount +
//               fareItem?.farePriceType[0]?.price?.fee[0]?.amount?.cdata *
//                 paxLength
//             : 0;
//         }
//       }
//     } else {
//       baseAmount = 0;
//       taxAmount = 0;
//       totalAmount = 0;
//       discountAmount = 0;
//       serviceFeeAmount = 0;
//     }
//   });
//   return {
//     baseAmount,
//     taxAmount,
//     totalAmount,
//     discountAmount,
//     serviceFeeAmount,
//   };
// };

/**
 * Calculate discount and service fee for supplier
 * @allOrderItems: all order items from ndc order detail Object
 * @payableOrderItems: remaining order items those need to be paid
 */
const getSupplierPaymentDiscountFees = (
  allOrderItems: any,
  payableOrderItems: any
) => {
  let discountAmount: number = 0;
  let serviceFeeAmount: number = 0;
  let paxLength: number = 0;

  if (!isEmpty(payableOrderItems) && !isEmpty(allOrderItems)) {
    const allPayableOrderIds = payableOrderItems?.map(
      (payableOrderItem: any) => payableOrderItem?.id
    );
    if (!isEmpty(allPayableOrderIds)) {
      allOrderItems
        ?.filter((allOrderItem: any) =>
          allPayableOrderIds.includes(
            allOrderItem?.orderItemId || allOrderItem?.offerItemId
          )
        )
        ?.map((orderItems: any) => {
          if (!isEmpty(orderItems["fareDetail"])) {
            for (const fareItem of orderItems["fareDetail"]) {
              if (fareItem?.farePriceType[0]?.farePriceTypeCode === "Net") {
                if (!isEmpty(fareItem?.paxRefId)) {
                  if (
                    fareItem?.paxRefId.length &&
                    fareItem?.paxRefId.length === 1 &&
                    fareItem?.paxRefId[0].search(" ") !== -1
                  ) {
                    paxLength = fareItem?.paxRefId[0]
                      .replace("[", "")
                      .replace("]", "")
                      .replaceAll("'", "")
                      .split(" ").length;
                  } else {
                    paxLength = fareItem?.paxRefId.length;
                  }
                }

                discountAmount =
                  !isEmpty(fareItem?.price?.discount) &&
                  fareItem?.price?.discount?.discountAmount
                    ? discountAmount +
                      fareItem?.price?.discount?.discountAmount?.cdata *
                        paxLength
                    : !isEmpty(fareItem?.farePriceType[0]?.price?.discount) &&
                      fareItem?.farePriceType[0]?.price?.discount
                        ?.discountAmount
                    ? discountAmount +
                      fareItem?.farePriceType[0]?.price?.discount
                        ?.discountAmount?.cdata *
                        paxLength
                    : 0;
                serviceFeeAmount = !isEmpty(fareItem?.price?.fee)
                  ? serviceFeeAmount +
                    fareItem?.price?.fee[0]?.amount?.cdata * paxLength
                  : !isEmpty(fareItem?.farePriceType[0]?.price?.fee)
                  ? serviceFeeAmount +
                    fareItem?.farePriceType[0]?.price?.fee[0]?.amount?.cdata *
                      paxLength
                  : 0;
              }
            }
          }
        });
    }
  }

  return {
    discountAmount,
    serviceFeeAmount,
  };
};

const getDifferenceInArrivalAndDepartureDays = (
  arrivalDate: any,
  departureDate: any
) => {
  const newArrivalDate: any = new Date(arrivalDate);
  const newDepartureDate: any = new Date(departureDate);
  const arrivalSplit = moment([
    newArrivalDate.getFullYear(),
    newArrivalDate.getMonth(),
    newArrivalDate.getDate(),
  ]);
  const departureSplit = moment([
    newDepartureDate.getFullYear(),
    newDepartureDate.getMonth(),
    newDepartureDate.getDate(),
  ]);
  return arrivalSplit.diff(departureSplit, "days");
};

const collectOfferPreBaggageDetails = (
  offerBaggageList: any,
  dataBaggageAllowanceList: any,
  dataPaxList: any = [],
  dataPaxJourneyList: any = [],
  listFilterBy: string = "",
  paxsegmentList: any
) => {
  if (
    !isEmpty(offerBaggageList) &&
    !isEmpty(dataBaggageAllowanceList?.baggageAllowance)
  ) {
    const baggageAllowanceList = dataBaggageAllowanceList?.baggageAllowance;
    const baggageAllowances: any[] = [];
    for (const offerBaggage of offerBaggageList) {
      const findBaggageAllowanceObjectData = baggageAllowanceList.find(
        (item: any) =>
          (item.baggageAllowanceId ===
            (offerBaggage?.baggageAllowanceId ||
              offerBaggage?.baggageAllowanceRefId?.[0]) &&
            //   offerBaggageList?.[0].baggageAllowanceRefId?.[0]) &&
            ((!isEmpty(item?.weightAllowance) &&
              item?.weightAllowance?.[0]?.maximumWeightMeasure !== "0") ||
              (!isEmpty(item?.pieceAllowance) &&
                item?.pieceAllowance?.totalQty !== "0"))) ||
          (!isEmpty(item?.weightAllowance) &&
            item?.weightAllowance?.[0]?.maximumWeightMeasure !== "0" &&
            !isEmpty(item?.pieceAllowance) &&
            item?.pieceAllowance?.totalQty !== "0")
      );
      if (!isEmpty(findBaggageAllowanceObjectData)) {
        const findBaggageAllowance = deepClone(findBaggageAllowanceObjectData);
        // offerBaggage["descText"] = ["$PaxRefID$:T1 T2", "$PaxJourneyRefID$:Ifltruxbp1fdsfvz9"];
        if (
          !isEmpty(offerBaggage?.descText) ||
          !isEmpty(offerBaggage?.paxRefId?.[0])
        ) {
          const findDescPaxRefIdText = offerBaggage?.descText?.find(
            (desc: any) => desc.search("PaxRefID") !== -1
          );
          if (
            (!isEmpty(findDescPaxRefIdText) ||
              !isEmpty(offerBaggage?.paxRefId?.[0])) &&
            !isEmpty(dataPaxList)
          ) {
            const paxRefIdArray = findDescPaxRefIdText?.split(":");
            const paxIdContent =
              !isEmpty(paxRefIdArray) && paxRefIdArray.length > 0
                ? paxRefIdArray[1]
                : "";
            // console.log("offerBaggage?.paxRefId", offerBaggage?.paxRefId);
            const paxIds = !isEmpty(paxIdContent)
              ? paxIdContent.split(" ")
              : offerBaggage?.paxRefId;
            const collectedPtc: any[] = [];
            if (!isEmpty(paxIds)) {
              for (const paxId of paxIds) {
                const findPtc = dataPaxList.find(
                  (list: any) => list.paxId === paxId
                );
                if (!isEmpty(findPtc)) {
                  if (!collectedPtc.includes(findPtc?.ptc)) {
                    collectedPtc.push(deepClone(findPtc?.ptc));
                  }
                }
              }
            }
            findBaggageAllowance["eligiblePtc"] = collectedPtc;
          }
          const findDescPaxJourneyRefIdText = offerBaggage?.descText?.find(
            (desc: any) => desc.search("PaxJourneyRefID") !== -1
          );

          let paxJourneyIds: any[] = [];
          paxsegmentList.map((paxsegment: any) => {
            paxJourneyIds.push(paxsegment?.paxJourneyRefId);
          });
          if (
            (!isEmpty(findDescPaxJourneyRefIdText) ||
              !isEmpty(
                // offerBaggage?.offerFlightAssociations?.paxJourneyRef
                //   ?.paxJourneyRefId
                //   paxsegment?.paxJourneyRefId
                paxJourneyIds
                //   journeyList.journeyPriceClass[0].paxJourneyRefId
              )) &&
            !isEmpty(dataPaxJourneyList)
          ) {
            const paxJourneyRefIdArray =
              findDescPaxJourneyRefIdText?.split(":");
            const paxJourneyIdContent =
              !isEmpty(paxJourneyRefIdArray) && paxJourneyRefIdArray.length > 0
                ? paxJourneyRefIdArray[1]
                : "";
            const paxJourneyRefIds = !isEmpty(paxJourneyIdContent)
              ? paxJourneyIdContent?.split(" ")
              : !isEmpty(
                  // paxsegment?.paxJourneyRefId
                  paxJourneyIds
                  // offerBaggage?.offerFlightAssociations?.paxJourneyRef
                  //   ?.paxJourneyRefId
                  //   journeyList?.journeyPriceClass?.[0]?.paxJourneyRefId
                )
              ? // ? offerBaggage?.offerFlightAssociations?.paxJourneyRef
                //     ?.paxJourneyRefId
                //     paxsegment?.paxJourneyRefId
                paxJourneyIds
              : [];
            let collectedSegmentIds: any[] = [];
            if (!isEmpty(paxJourneyRefIds)) {
              for (const paxJourneyRefId of paxJourneyRefIds) {
                const findJourney = dataPaxJourneyList.find(
                  // (list: any) => list.paxJourneyId === paxJourneyRefId
                  (list: any) => list.paxJourneyId === paxJourneyRefId
                );
                if (
                  !isEmpty(findJourney) &&
                  !isEmpty(findJourney?.paxSegmentRefId)
                ) {
                  for (const segRefId of deepClone(
                    findJourney?.paxSegmentRefId
                  )) {
                    collectedSegmentIds.push(segRefId);
                  }
                }
              }
            }
            findBaggageAllowance["paxSegmentRefIds"] = collectedSegmentIds;
          }
          // })
        }
        baggageAllowances.push(findBaggageAllowance);
      }
    }
    // let uniq: any = {};
    // let filterUniq: any[] = [];
    // if (!isEmpty(baggageAllowances)) {
    //   filterUniq = baggageAllowances.filter(
    //     (obj: any) => !uniq[obj.eligiblePtc] && (uniq[obj.eligiblePtc] = true)
    //   );
    //   return filterUniq;
    // } else {
    //   return [];
    // }
    if (!isEmpty(baggageAllowances)) {
      let uniq: any = {};
      let filterUniq: any[] = [];
      if (listFilterBy === "SEG") {
        const baggageAllowanceDiffBySeg: any[] = [];
        for (const allowance of baggageAllowances) {
          if (!isEmpty(allowance?.paxSegmentRefIds)) {
            for (const segRefId of allowance?.paxSegmentRefIds) {
              const newAllowance = deepClone(allowance);
              newAllowance["paxSegmentRefIds"] = [segRefId];
              baggageAllowanceDiffBySeg.push(newAllowance);
            }
          }
        }
        // console.log("baggageAllowanceDiffBySeg", baggageAllowanceDiffBySeg);
        filterUniq = baggageAllowanceDiffBySeg.filter((obj: any) => {
          return (
            !uniq[
              obj.eligiblePtc + "" + obj.paxSegmentRefIds + "" + obj.typeCode
            ] &&
            (uniq[
              obj.eligiblePtc + "" + obj.paxSegmentRefIds + "" + obj.typeCode
            ] = true)
          );
        });
      } else {
        filterUniq = baggageAllowances.filter(
          (obj: any) => !uniq[obj.eligiblePtc] && (uniq[obj.eligiblePtc] = true)
        );
      }
      if (!isEmpty(filterUniq)) {
        return filterUniq;
      } else {
        return baggageAllowances;
      }
    } else {
      return baggageAllowances;
    }
  } else {
    return [];
  }
};

const collectOfferPreBaggageDetailsByService = (
  orderItems: any,
  dataList: any,
  listFilterBy: string = ""
) => {
  if (
    !isEmpty(orderItems) &&
    !isEmpty(dataList?.baggageAllowanceList?.baggageAllowance) &&
    !isEmpty(dataList?.serviceDefinitionList?.serviceDefinition)
  ) {
    const baggageAllowanceList =
      dataList?.baggageAllowanceList?.baggageAllowance;
    const serviceDefinitionList =
      dataList?.serviceDefinitionList?.serviceDefinition?.filter(
        (item: any) => !isEmpty(item)
      );
    const dataPaxList = dataList?.paxList?.pax;
    const baggageAllowances: any[] = [];
    for (const orderItem of orderItems) {
      if (!isEmpty(orderItem?.service)) {
        for (const orderItemService of orderItem?.service) {
          // if (orderItemService?.descText === BAGGAGE) {
          const serviceDefinitionRefId =
            orderItemService?.orderServiceAssociation?.serviceDefinitionRef
              ?.serviceDefinitionRefId;
          if (serviceDefinitionRefId) {
            const findServiceDefinationId = serviceDefinitionList.find(
              (list: any) => list.serviceDefinitionId === serviceDefinitionRefId
            );
            if (findServiceDefinationId) {
              const baggageAllowanceRefId =
                findServiceDefinationId?.serviceDefinitionAssociation
                  ?.baggageAllowanceRef?.baggageAllowanceRefId;
              if (baggageAllowanceRefId) {
                // const findBaggageAllowanceObjectData =
                //   baggageAllowanceList.find(
                //     (list: any) =>
                //       list?.baggageAllowanceId ===
                //         baggageAllowanceRefId &&
                //         (!isEmpty(list?.weightAllowance) ||
                //           !isEmpty(list?.pieceAllowance)) ||
                //       (!isEmpty(list?.weightAllowance) &&
                //         !isEmpty(list?.pieceAllowance))
                //   );
                const findBaggageAllowanceObjectData =
                  baggageAllowanceList.find(
                    (list: any) =>
                      list?.baggageAllowanceId === baggageAllowanceRefId[0] &&
                      ((!isEmpty(list?.weightAllowance) &&
                        list?.weightAllowance?.[0]?.maximumWeightMeasure !==
                          "0") ||
                        (!isEmpty(list?.pieceAllowance) &&
                          list?.pieceAllowance?.totalQty !== "0") ||
                        (!isEmpty(list?.weightAllowance) &&
                          list?.weightAllowance?.[0]?.maximumWeightMeasure !==
                            "0" &&
                          !isEmpty(list?.pieceAllowance) &&
                          list?.pieceAllowance?.totalQty !== "0"))
                  );
                if (!isEmpty(findBaggageAllowanceObjectData)) {
                  const findBaggageAllowanceObject = deepClone(
                    findBaggageAllowanceObjectData
                  );
                  var segmentRefIds = orderItemService?.serviceId;
                  var splitSegment = segmentRefIds.split("_");
                  var paxsegmentRefId = splitSegment[0];
                  findBaggageAllowanceObject["paxSegmentRefId"] =
                    orderItemService?.orderServiceAssociation?.serviceDefinitionRef?.orderFlightAssociations?.paxSegmentRef?.paxSegmentRefId;
                  // paxsegmentRefId;
                  // orderItemService?.orderServiceAssociation?.paxSegmentRef?.paxSegmentRefId;
                  findBaggageAllowanceObject["eligiblePtc"] = dataPaxList?.find(
                    (list: any) => list.paxId === orderItemService?.paxRefId
                  )?.ptc;
                  baggageAllowances.push(findBaggageAllowanceObject);
                }
              }
            }
          }
          // }
        }
      }
    }

    if (!isEmpty(baggageAllowances)) {
      let uniq: any = {};
      let filterUniq: any[] = [];
      if (listFilterBy === "SEG") {
        filterUniq = baggageAllowances.filter(
          (obj: any) =>
            !uniq[
              obj.eligiblePtc + "" + obj.paxSegmentRefId + "" + obj.typeCode
            ] &&
            (uniq[
              obj.eligiblePtc + "" + obj.paxSegmentRefId + "" + obj.typeCode
            ] = true)
        );
      } else {
        filterUniq = baggageAllowances.filter(
          (obj: any) => !uniq[obj.eligiblePtc] && (uniq[obj.eligiblePtc] = true)
        );
      }
      if (!isEmpty(filterUniq)) {
        return filterUniq;
      } else {
        return baggageAllowances;
      }
    } else {
      return baggageAllowances;
    }
  } else {
    return [];
  }
};
const airlineStandardCommission = (commissionArray: any) => {
  const percentage = commissionArray[0]?.percentage;
  const currencyCode = commissionArray[0]?.amount?.curCode;

  const commission = !isEmpty(commissionArray[0]?.amount?.cdata)
    ? Number(commissionArray[0]?.amount?.cdata)
    : null;
  return {
    percentage,
    currencyCode,
    commission,
  };
};

const getCurrentIataDetails = () => {
  if (window.sessionStorage) {
    const sessionIataList: any = window.sessionStorage.getItem(IATA_LIST) || [];
    if (!isEmpty(sessionIataList)) {
      const iataList = JSON.parse(sessionIataList);
      const defaultIataId: any = window.sessionStorage.getItem("defaultIataID");
      const activeIataRecord =
        !isEmpty(iataList) && !isEmpty(defaultIataId)
          ? iataList.find((list: any) => list.node.id === defaultIataId)
          : null;
      if (!isEmpty(activeIataRecord)) {
        return activeIataRecord?.node;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getPenaltyAmount = (orderItems: any) => {
  let penaltyAmount: any = "0.00";
  if (!isEmpty(orderItems)) {
    const collectPenaltyOrders: any = [];
    for (const orderItem of orderItems) {
      if (!isEmpty(orderItem["service"])) {
        const findPenaltyOrder = orderItem["service"].find(
          (serv: any) => serv?.descText === PENALTY_CODE
        );
        if (!isEmpty(findPenaltyOrder)) {
          collectPenaltyOrders.push(orderItem);
        }
      }
    }
    if (!isEmpty(collectPenaltyOrders)) {
      penaltyAmount = Number(
        collectPenaltyOrders.reduce(
          (total: number, order: any) =>
            total + Number(order?.price?.totalAmount?.cdata),
          0.0
        )
      ).toFixed(2);
    } else {
      penaltyAmount = "0.00";
    }
  }
  return penaltyAmount;
};

const removeDuplicates = (array: any) => {
  const uniqueArray: any = [];
  array.forEach(
    (item: any) => uniqueArray.indexOf(item) === -1 && uniqueArray.push(item)
  );
  return uniqueArray;
};

const isSubset = (arr1: Array<any>, arr2: Array<any>): Boolean => {
  for (var i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i]) === -1) {
      return false;
    }
  }
  return true;
};

const hasCommonElement = (arr1: Array<any>, arr2: Array<any>): Boolean => {
  for (var i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i]) !== -1) {
      return true;
    }
  }
  return false;
};

const serviceConfigValue = (
  ownerCode: string,
  serviceName: string,
  airlineServiceConfigs: any
) => {
  const findServiceConfigItem =
    !isEmpty(airlineServiceConfigs) &&
    airlineServiceConfigs?.find(
      (config: any) => config.ownerCode === ownerCode
    );
  if (!isEmpty(findServiceConfigItem)) {
    const findService = findServiceConfigItem?.serviceConfigs?.find(
      (item: any) => item?.keyText === serviceName
    );
    if (!isEmpty(findService)) {
      return findService?.replacedValueText;
    } else {
      return null;
    }
  }
};

const ownerCodeValue = (ownerCode: string, airlineServiceConfigs: any) => {
  if (!isEmpty(airlineServiceConfigs)) {
    const isLocalInventory = getSessionStorage(IS_LOCAL_INVENTORY_SUBSCRIPTION);
    // const firstAirlineOwnerCode = airlineServiceConfigs?.[0]?.ownerCode;
    const airlineOwnerCode =
      isLocalInventory === null
        ? ownerCode
        : isLocalInventory === true
        ? LI_OWNER_CODE
        : ownerCode;
    if (airlineOwnerCode === LI_OWNER_CODE) {
      return LI_OWNER_CODE;
    } else {
      return ownerCode;
    }
  } else {
    return ownerCode;
  }
};

export const airlinePenaltyAmountByPenaltyRefId = (
  offerItem: any,
  penaltyList: any
) => {
  const fareDetail = offerItem?.map(
    (item: any) => item?.reshopPrice?.priceDifferential?.diffPrice?.fareDetail
  );

  if (
    !isEmpty(fareDetail) &&
    !fareDetail?.every((itm: any) => itm === undefined) &&
    !isEmpty(penaltyList)
  ) {
    let penaltyRefIds: any = [];
    for (const fareDetailItms of fareDetail) {
      for (const item of fareDetailItms) {
        if (!isEmpty(item?.penaltyRefId)) {
          // penaltyRefIds = [...penaltyRefIds, ...item?.penaltyRefId];
          penaltyRefIds.push(item?.penaltyRefId?.[0]);
        }
      }
    }
    if (!isEmpty(penaltyRefIds)) {
      const collectPenaltyRecords: any = [];
      for (const refId of penaltyRefIds) {
        const penaltyRecord = penaltyList?.find(
          (list: any) => list?.penaltyId === refId
          // (list: any) => list?.penaltyId === refId && list.typeCode === "Change"
        );
        if (!isEmpty(penaltyRecord)) {
          collectPenaltyRecords.push(penaltyRecord);
        }
      }
      const totalPenaltyAmount = collectPenaltyRecords?.reduce(
        (total: any, penalty: any) =>
          total + Number(penalty?.price?.totalAmount?.cdata),
        0.0
      );
      return totalPenaltyAmount || 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
const ifIncludedRemoveOrAdd = (Array: Array<any>, index: any) => {
  const tempArray = !isEmpty(Array) ? deepClone(Array) : [];
  tempArray?.includes(index)
    ? tempArray.splice(tempArray.indexOf(index), 1)
    : tempArray.push(index);
  return tempArray;
};

const constructPaxListRequest = (paxList: any) => {
  if (!isEmpty(paxList)) {
    const collectPaxRecords: any = [];
    for (const list of paxList) {
      const obj = {
        paxId: list?.paxId,
        ptc: list?.ptc,
        individual: {
          birthdate: list?.individual?.birthdate,
          genderCode: list?.individual?.genderCode,
          titleName: list?.individual?.titleName,
          givenName: list?.individual?.givenName,
          surname: list?.individual?.surname,
          individualId: list?.paxId,
        },
        identityDoc: [
          {
            identityDocTypeCode: list?.identityDoc?.[0]?.identityDocTypeCode,
            identityDocId: list?.identityDoc?.[0]?.identityDocId,
            issuingCountryCode: list?.identityDoc?.[0]?.issuingCountryCode,
            residenceCountryCode: list?.identityDoc?.[0]?.issuingCountryCode,
            surname: list?.identityDoc?.[0]?.surname,
            expiryDate: list?.identityDoc?.[0]?.expiryDate,
          },
        ],
        langUsage: [
          {
            langCode: "EN",
          },
        ],
      };
      if (isEmpty(obj?.identityDoc?.[0]?.surname)) {
        obj.identityDoc = [];
      }
      collectPaxRecords.push(obj);
    }
    return collectPaxRecords;
  } else {
    return [];
  }
};

export {
  jwt,
  emptyProxyObject,
  nodeEnv,
  convertCurrencyLocale,
  getEnv,
  verifyAuthToken,
  isEmpty,
  setLocalStorage,
  getLocalStorage,
  getSessionStorage,
  clearLocalStorage,
  counterValueHandler,
  dateChangeHandler,
  buildDateToSQLFmt,
  buildDateFromSQLFmt,
  compareObjects,
  convertDurationForUI,
  deepClone,
  generateRedirectAppUrl,
  transactionIdMapper,
  convertPassengerDetails,
  convertPassengerToMd5,
  getPassengerListByHash,
  getMissedPassengerListByHash,
  getOrderBookedType,
  orderBookedBy,
  setSessionStorage,
  clearSessionStorage,
  ageCalculator,
  carrierFlightCodeAndNumber,
  collectOfferPreBaggageDetails,
  collectOfferPreBaggageDetailsByService,
  // getCalculatedFareAmounts,
  baseFareWithServiceFee,
  getDifferenceInArrivalAndDepartureDays,
  getSupplierPaymentDiscountFees,
  airlineStandardCommission,
  getCurrentIataDetails,
  getPenaltyAmount,
  removeDuplicates,
  isSubset,
  hasCommonElement,
  serviceConfigValue,
  ownerCodeValue,
  ifIncludedRemoveOrAdd,
  constructPaxListRequest,
};
